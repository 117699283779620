import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";

export default ({location}) => {

    return (<ThankYouPage location={location}>
        <div className="white-paper-thank-you">
            <h1>Thank you!</h1>

            <p>Your White Paper will be delivered to your Inbox shortly.</p>
            <p>Wishing you an amazing day further!</p>
            <p><a className="btn-boldish amazeeio-button rounded-full px-4 py-2 inverted inline-block mt-2" href="/resources">View our additional Resources</a></p>
        </div>
        </ThankYouPage>
    );
}